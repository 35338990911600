import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import CommentList from './CommentList';
import VoteContainer from './VoteContainer';
import {
    IconButton,
    Button,
    TextField,
    MenuItem,
    Card,
    Fab,
    Grid,
    GridList,
} from "@material-ui/core";
import PostForm from "./PostForm";
import {
    fade,
    makeStyles,
} from '@material-ui/core/styles';
import { TimeAgo } from "@n1ru4l/react-time-ago";
import Typography from '@material-ui/core/Typography';




const Post = ({
    post,
    // user,
    currentUser,
    handleSubmit,
    index,
    editingIndex,
    setEditingIndex,
    deletePost
}) => {
    const useStylesFeed = makeStyles((theme) => ({
        root: {
            // border: '1px solid #e2e2e1',
            // overflow: 'hidden',
            // backgroundColor: '#fcfcfb',
            // transition: "all 300ms ease",
            // '&$focused': {
            //     backgroundColor: '#fff',
            //     boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            // },
            // "& .action-icon, & .rectangle-box": {
            //     opacity: 0.76
            // },
            button: {
                margin: theme.spacing(1)
            },
            input: {
                display: "none"
            },
            padding: 4,
        },
        focused: {
            // '&:hover': {
            //     background: "rgba(0,0,0, .04)",
            //     overflow: "hidden",
            // },
        },
    }));
    const classes = useStylesFeed();
    // to do: handleDownvote
    const handleDownvote = () => { };
    const handleEdit = () => setEditingIndex(index);
    const handleDelete = () => deletePost(index);
    const renderFormatted = (body) => {
        let videoUrl = extractVideoUrl(body)
        return body && body.replace(videoUrl, "")
    }

    const extractVideoUrl = (body) => {
        let videoUrl = "";
        if (body && body.indexOf("http") >= 0 && body.indexOf("youtu") >= 0) {
            let tokens = body.split(" ")
            tokens = tokens.filter(token => token.indexOf("http") >= 0 && token.indexOf("youtu") >= 0)
            videoUrl = tokens[0]
        }
        return videoUrl;
    }

    const getPostCard = () => {

        if (post) {
            return (

                <div className="margin-bottom-additional background-light-grey">
                    <div className={`${classes.root} box border-radius-top justify-start`} >
                        {/* <div className={`${classes.root} flex items-center flex-column justify-start`}> */}
                        <div key={post.id}>
                            {/* <Card
                                // className="flex-column"
                                > */}
                            <Grid>
                                <div className="content ">

                                    <h6>

                                        <div className="flex justify-around justify-between">
                                            <Typography
                                                color="textSecondary"

                                                noWrap="false"
                                                variant="overline"
                                            >
                                                @{post.username}
                                            </Typography>
                                            <Typography
                                                color="textSecondary"

                                                noWrap="false"
                                                variant="overline"
                                            >
                                                <TimeAgo date={new Date(post.created_at)}>{({ value }) => <>{value} </>}</TimeAgo>
                                            </Typography>

                                        </div>
                                    </h6>
                                    <h4 className="title">
                                        {post.title}



                                    </h4>


                                    <h6 className="body">{renderFormatted(post.body)}</h6>
                                    <div>
                                        {post.video_url &&
                                            <div className="player-wrapper">
                                                <ReactPlayer
                                                    config={{ 
                                                        youtube:{
                                                            origin: "https://yeti.netlify.app", 

                                                            playerVars: { 
                                                                origin: "https://yeti.netlify.app" 
                                                            }
                                                        }
                                                    }}
                                                    className='react-player'
                                                    url={post.video_url}
                                                    width='100%'
                                                    height='100%'
                                                    controls
                                                    onReady={() => console.log('onReady callback')}
                                                    onStart={() => console.log('onStart callback')}
                                                    onPause={() => console.log('onPause callback')}
                                                    onEnded={() => console.log('onEnded callback')}
                                                    onError={() => console.log('onError callback')}
                                                />
                                            </div>}
                                    </div>
                                    <div className="vote-container ">
                                        <VoteContainer
                                            postId={post.id}
                                            currentUser={currentUser}
                                        />
                                    </div>
                                </div>

                                {(post.user_id === currentUser.id) &&
                                    (
                                        <div className="padding-sides">
                                            <div className="flex justify-around justify-between ">

                                                <Button
                                                    variant="outlined"
                                                    color="default"
                                                    className="justify-between"
                                                    onClick={handleEdit}>
                                                    <Typography
                                                        color="textSecondary"
                                                    >
                                                        Edit  </Typography>
                                                </Button>
                                                <p className="flex justify-around justify-between"></p>
                                                <Button
                                                    variant="outlined"
                                                    color="default"
                                                    onClick={handleDelete}>
                                                    <Typography
                                                        color="textSecondary"
                                                    >
                                                        Delete </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }

                            </Grid>
                            <CommentList
                                postId={post.id}
                                currentUser={currentUser}
                            />
                        </div>
                    </div >
                </div>)
        }
        return null;
    }

    if (!post) {
        return null
    }

    return (index === editingIndex ? (

        <div>
            <PostForm
                handleSubmit={handleSubmit}
                title={post.title}
                body={post.body}
                postId={post.id}
                index={index}
            />
        </div>
    ) :
        post && getPostCard())
}

export default Post;