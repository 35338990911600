import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Login from './components/Login'
import Register from './components/Register'
import Feed from './components/Feed'
import {
  loginUser,
  registerUser,
  verifyUser,
  removeToken
} from './services/api-helper'
import './App.css';
import Landing from './components/Landing';
import NavigationResponsive from './components/NavigationResponsive';
import Footer from './components/Footer'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      authFormData: {
        username: "",
        email: "",
        password: "",
      }
    };
  }

  async componentDidMount() {
    const currentUser = await verifyUser();
    if (currentUser) {
      this.setState({ currentUser })
    }
  }

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      createPostForm: {
        ...prevState.createPostForm,
        [name]: value
      }
    }))
  }

  resetForm = () => {
    this.setState({
      createPostForm: {
        title: "",
        body: ""
      }
    })
  }

  // Authentication

  handleLoginButton = () => {
    this.props.history.push("/login")
  }

  handleLogin = async () => {
    const currentUser = await loginUser(this.state.authFormData);
    this.setState({ currentUser });
    this.props.history.push("/feed")
  }

  handleRegister = async (e) => {
    e.preventDefault();
    await registerUser(this.state.authFormData);
    this.props.history.push("/login")
  }

  handleLogout = () => {
    localStorage.removeItem("authToken");
    removeToken();
    this.setState({
      currentUser: null
    })
    this.props.history.push("/")
  }

  authHandleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      authFormData: {
        ...prevState.authFormData,
        [name]: value
      }
    }));
  }

  render() {
    return (
      <div className="App" >
        <NavigationResponsive
          handleLoginButton={this.handleLoginButton}
          handleLogout={this.handleLogout}
          currentUser={this.state.currentUser}
        />

        <Route
          exact path="/feed" render={(props) => (
            <div >
              <Feed

                {...props}
                currentUser={this.state.currentUser}
              />
            </div>
          )}
        />

        <Route exact path="/login" render={(props) => (
          <Login {...props}
            handleLogin={this.handleLogin}
            handleChange={this.authHandleChange}
            formData={this.state.authFormData} />)} />
        <Route exact path="/register" render={() => (
          <Register
            handleRegister={this.handleRegister}
            handleChange={this.authHandleChange}
            formData={this.state.authFormData} />)} />
        <Route
          exact path="/" render={(props) => (
            <div className="margin-custom">
              <Landing

                {...props}
                currentUser={this.state.currentUser}
              />
              <Footer />

            </div>
          )}

        />
      </div>
    );
  }
}

export default withRouter(App);