import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { NavLink, Link } from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListIcon from '@material-ui/icons/List';
import CustomizedButtons2 from './CustomizedButtons2'
import CustomizedButtons from './CustomizedButtons'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 12,
  },
  title: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },

  sectionDesktop: {
    display: 'none',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default function NavigationResponsive(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (

    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ backgroundColor: 'rgba(0,0,0,0.65)', color: '#fff' }}
    >
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}

        >
          <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
            <Link to='/' onClick={props.resetForm}>
              <h2>
                yetibook
              </h2>
            </Link>
          </MenuItem>
          <div>
            {props.currentUser
              ?
              <>
                <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
                  <Link onClick={props.handleLogout}>Log Out</Link>
                </MenuItem>
              </>
              :
              <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
                <Link onClick={props.handleLoginButton}>Sign In</Link>
              </MenuItem>
            }
          </div>
        </Menu>
      </div>

      {props.currentUser
        ?
        <>
          <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
            <small className="header-menu-item"> {`Welcome,`}</small>
            <small><b className="header-menu-item gradient"> {` ${props.currentUser.username} `}</b></small>
            <small className="header-menu-item"> {` 💎`}</small>
          </MenuItem>
          <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
            <NavLink to="/feed" className="header-menu-item">
              <IconButton color="inherit">
                <ListIcon />
              </IconButton>
              Feed
                </NavLink>
          </MenuItem>
          <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
            <NavLink to="/" className="header-menu-item" onClick={props.handleLogout}>
              <IconButton color="inherit">
                <PowerSettingsNewIcon />
              </IconButton>
              Log Out
              </NavLink>
          </MenuItem>
        </>
        :
        <>
          <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
            <NavLink to="/login" className="header-menu-item" onClick={props.handleLogin}>
              <IconButton color="inherit">
                <AccountCircle />

              </IconButton>

              Log In
            </NavLink>
          </MenuItem>

          <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
            <NavLink to="/register" className="header-menu-item" onClick={props.handleRegister}>
              <IconButton color="inherit">

                <ExitToAppIcon />

              </IconButton>
              Register
            </NavLink>
          </MenuItem>
        </>
      }




    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="sticky" variant="dense" style={{ backgroundColor: '#161436', color: '#fff' }}>
        <Toolbar style={{ backgroundColor: '#161436', color: '#fff' }}>


          {props.currentUser
            ?
            <MenuItem>
              <NavLink
                to='/feed'
                className="header-menu-item"
              >
                <h2>
                  yetibook
              </h2>
              </NavLink>
            </MenuItem>
            :
            <MenuItem>
              <NavLink
                to='/'
                className="header-menu-item"
              >
                <h2>
                  yetibook
              </h2>
              </NavLink>
            </MenuItem>
          }


          <div className={classes.grow} style={{ backgroundColor: '#161436', color: '#fff' }} />
          <div className={classes.sectionDesktop} style={{ backgroundColor: '#161436', color: '#fff' }}>

            <div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}

              >
                <MenuItem>

                  <Link to='/' onClick={props.resetForm}>
                    <h2>
                      yetibook
                 </h2>
                  </Link>
                </MenuItem>
                <div>
                  {props.currentUser
                    ?
                    <>
                      <MenuItem>
                        <Link onClick={props.handleLogout}>Log Out</Link>
                      </MenuItem>
                    </>
                    :
                    <MenuItem>
                      <Link onClick={props.handleLoginButton}>Sign In</Link>
                    </MenuItem>
                  }
                </div>
              </Menu>
            </div>

            {props.currentUser
              ?
              <>
                <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>

                  <small className="header-menu-item"> {`Welcome,`}</small>

                  <small><b className="header-menu-item gradient"> {` ${props.currentUser.username} `}</b></small>
                  <small className="header-menu-item"> {` 💎`}</small>

                </MenuItem>

                <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
                  <NavLink to="/feed" className="header-menu-item">
                    <IconButton color="inherit">
                      <ListIcon />
                    </IconButton>
                    Feed
                  </NavLink>
                </MenuItem>

                <MenuItem style={{ backgroundColor: '#161436', color: '#fff' }}>
                  <NavLink to="/" className="header-menu-item" onClick={props.handleLogout}>
                    <IconButton color="inherit">

                      <PowerSettingsNewIcon />

                    </IconButton>

                    Log Out
                            </NavLink>
                </MenuItem>

              </>
              :
              <>
                <NavLink to="/login" className="header-menu-item" onClick={props.handleLogin}>
                  <CustomizedButtons
                    variant="outlined"
                    color="primary"
                  >
                    <Typography

                      color="secondaryText"
                    >
                      Login
                    </Typography>
                  </CustomizedButtons>
                </NavLink>

                <NavLink to="/register" className="header-menu-item" onClick={props.handleRegister}>
                  <CustomizedButtons2
                    color="secondary"
                    variant="outlined"

                  >
                    <Typography>
                      Join
                    </Typography>
                  </CustomizedButtons2>
                </NavLink>
              </>

            }

          </div>
          <div className={classes.sectionMobile}>
            <MenuItem

              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </MenuItem>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}

    </div>
  );
}
