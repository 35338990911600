import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom'
import CustomButton from './CustomButton'
import CustomButton2 from './CustomButton2'



const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Copyright() {
  const classes = useStyles();
  return (

    <div className="margin-custom-bottom">
      <footer className={classes.footer}>
        <Typography variant="body2" color="textSecondary" align="center">

          <Link color="inherit" href="https://stefwong.com/">
            <div className="white">{'Copyright © '} Stef{' '}  {new Date().getFullYear()}
              {'.'}</div>

          </Link>

        </Typography>
      </footer>
    </div>
  );
}