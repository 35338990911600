import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const styles = {
  button: {
    background: 'linear-gradient(45deg, var(--background-start) 30%, var(--background-end) 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
};

class CustomButton2 extends React.Component {
  state = {
    color: 'default',
  };

  handleChange = event => {
    this.setState({ color: event.target.checked ? 'blue' : 'default' });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>

        <Button
          type="submit"
          className={classes.button}
          style={

            {
              '--background-start': '#263238',
              '--background-end': '#263238',
            }
          }
        >

          {'Join'}

        </Button>
      </React.Fragment>
    );
  }
}

CustomButton2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomButton2);