import React, { useState, useEffect } from 'react';
import {
    getAllVotes, createVote
} from '../services/api-helper'
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import { green, purple, lightBlue, grey, indigo, teal, deepPurple, red } from '@material-ui/core/colors';

const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: teal[300],
        color: theme.palette.getContrastText(teal[400]),
        
        '&:hover': {
            color: theme.palette.getContrastText(teal[400]),
            backgroundColor: teal['A400'],
        },
    },
}))(Button);


function VoteContainer({ postId, currentUser }) {
    const [votes, setVotes] = useState(null)
    useEffect(() => {
        getAllVotes(postId).then(resp => {
            setVotes(resp)
        })
            .catch(error => {
                console.log(error);
            });
    }, [postId])
    const handleUpvote = () => {
        let newVote = { user_id: currentUser.id, post_id: postId, vote_type: 1 }
        createVote(newVote)
            .then(resp => {
                setVotes(resp)
            })
            .catch(error => {
                console.log(error);
            })
    }
    const handleDownvote = () => {
        let newVote = { user_id: currentUser.id, post_id: postId, vote_type: 0 }
        createVote(newVote)
            .then(resp => {
                setVotes(resp)
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <>
            <ColorButton
                variant="contained"
                color="secondary"
                disableElevation
                size="small"
                onClick={handleUpvote}
            >
                <ArrowUpwardIcon
                    className="flex padding-right-vote"
                />
                {` upvote `}
            </ColorButton>
            <Button
                color="default"
                disabled
                disableElevation
                size="small"
                onClick={handleUpvote}
            >
                {`${votes} votes`}
            </Button>

            <Button
                size="large"
                disableElevation
                onClick={handleDownvote}
                variant="contained"
            >
                <ArrowDownwardIcon

                />
            </Button>
        </>
    );
}

export default VoteContainer;