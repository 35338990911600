import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavLink } from 'react-router-dom'
import CustomizedButtons2 from './CustomizedButtons2'
import CustomizedButtons from './CustomizedButtons'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    color: theme.palette.common.white,
    padding: theme.spacing(12, 0, 18),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%',
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Landing() {
  const classes = useStyles();

  return (
    <div >
      {/* <CssBaseline /> */}
      {/* <main> */}
        <div className={`${classes.heroContent} landing`}>
          {/* <Container maxWidth="sm" > */}
            <Typography component="h3" variant="h3" align="center" color="innherit" gutterBottom>
              <h3 >
                yetibook
              </h3>
            </Typography>
            <Typography variant="h4" align="center" color="inherit" paragraph>
              <h4 className="purple" >Post cool stuff</h4>

            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <NavLink to="/login">

                    <CustomizedButtons>
                      Log In
                  </CustomizedButtons>
                  </NavLink>
                </Grid>
                <Grid item>
                  <NavLink to="/register">
                    <CustomizedButtons2 size="large">
                      Sign Up
                  </CustomizedButtons2>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          {/* </Container> */}
        </div>
      {/* </main> */}
    </div>
  );
}