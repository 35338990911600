import React from 'react';
import Post from './Post'

const PostList = ({
    posts,
    currentUser,
    handleSubmit,
    editingIndex,
    setEditingIndex,
    deletePost
}) => {


    let postDiv = posts.map((post, index) => {

        return (
            <div className="border-radius-bottom width-100p margin-sides height-custom">
                <Post
                    post={post}
                    index={index}
                    currentUser={currentUser}
                    handleSubmit={handleSubmit}
                    setEditingIndex={setEditingIndex}
                    editingIndex={editingIndex}
                    deletePost={deletePost}
                />
                
            </div>
           
        )
    })
    return postDiv
}

export default PostList;