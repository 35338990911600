import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Grid,
} from "@material-ui/core";
import {
  makeStyles,
} from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CustomButton2 from './CustomButton2'
import Typography from '@material-ui/core/Typography';




const useStylesPost = makeStyles((theme) => ({
  root: {
 
    color: '#fff',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#2F2D56',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#161436',
      color: '#fff',
      borderRadius: 0,
      borderBottom: '1px solid #2F2D56',
    },
    wrapper: {
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    button: {
      margin: theme.spacing(1)
    },
    input: {
      display: "none"
    }
  },
}));

const Register = (props) => {
  const classes = useStylesPost();
  return (

    <div className="margin" >

      <div className="signup flex justify-center">
        <div className="p-8">
          {/* <Card className="signup-card position-relative y-center width-80vw"> */}
            <Grid container className="justify-center">
              {/* <Grid item lg={5} md={5} sm={5} xs={4} >
                <div className="margin-left padding-top y-center width-20vw">
                    <img
                  src="https://images.unsplash.com/photo-1587302186428-d3753405ffed?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80"
                  alt=""
                />
                </div>
              </Grid> */}
              <Grid item lg={7} md={7} sm={10} xs={12}>
              {/* <Grid item lg={7} md={7} sm={7} xs={12}> */}
         
                <div className="margin-top width-80p margin-left y-center">
                  <ValidatorForm
                    onSubmit={props.handleRegister}
                  >
            <Typography component="h4" variant="h4" color="innherit" className="white" gutterBottom>
              <h4 >
                Join yetibook
              </h4>
            </Typography>
                    <TextValidator
                      className="mb-6 w-full"
                      id="username"
                      label="username"
                      multiline
                      rowsMax="1"
                      variant="filled"
                      InputProps={{ classes, disableUnderline: true }} {...props}
                      name="username"
                      type="text"
                      value={props.formData.username}
                      onChange={props.handleChange}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />

                    <TextValidator
                      className="mb-6 w-full"
                      id="email"
                      label="email"
                      multiline
                      rowsMax="1"
                      variant="filled"
                      InputProps={{ classes, disableUnderline: true }} {...props}
                      name="email"
                      type="text"
                      value={props.formData.email}
                      onChange={props.handleChange}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />

                    <TextValidator
                      className="mb-6 w-full"
                      variant="filled"
                      InputProps={{ classes, disableUnderline: true }} {...props}InputProps={{ classes, disableUnderline: true }} {...props}
                      label="password"
                      onChange={props.handleChange}
                      type="text"
                      name="password"
                      type="password"
                      value={props.formData.password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <TextValidator
                      className="mb-6 w-full"
                      variant="filled"
                      InputProps={{ classes, disableUnderline: true }} {...props}
                      label="confirm password"
                      onChange={props.handleChange}
                      type="text"
                      name="password_confirmation"
                      type="password"
                      value={props.formData.password_confirmation}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />

                    <CustomButton2
                      type="submit"
                    >Join</CustomButton2>
                    <p></p>
                    <Link className="white" to="/login">Have an account? Sign In</Link>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          {/* </Card> */}
        </div>
      </div>
    </div>
  );
}

export default Register;
