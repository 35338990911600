import React, { useState } from 'react';
import {
    IconButton,
    MenuItem,
} from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from "@material-ui/core/Menu";
import { TimeAgo } from "@n1ru4l/react-time-ago";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function Comment({ comment, index, handleClick, handleClose, handleDelete, open, anchorEl }) {

    const handleDeleteInternal = () => {
        handleDelete(index)
    }
    const displayMoreIcon = (comment) => {

        return (
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            width: '20ch',
                        },
                    }}
                >

                    <MenuItem
                        variant="outlined"
                        key={`delete${comment.id}`}
                        onClick={handleDeleteInternal}>
                        <Typography
                            variant="overline"
                        >
                            delete
                        </Typography>
                    </MenuItem>
                </Menu>
            </div>
        )
    }
    return (

        <div key={comment.id}>
            <div>
                <Grid className="box ">
                    <div className="flex justify-around justify-between">
                        <Typography
                            className="flex"
                            color="textSecondary"
                            noWrap="false"
                            variant="overline"
                        >
                            <>@{comment.user.username}</>
                        </Typography>

                        <Typography
                            color="textSecondary"
                            noWrap="false"
                            variant="overline"
                        >
                            <TimeAgo date={new Date(comment.created_at)}>{({ value }) => <>{value} </>}
                            </TimeAgo>
                        </Typography>
                    </div>
                    <Typography
                        color="textPrimary"
                        paragraph="true"
                    >
                    <body className="body background-comment">{comment.body}</body>
                    </Typography>

                    {displayMoreIcon(comment)}

                </Grid>
            </div>
        </div>
    );
}

export default Comment;
