import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple, blue, grey, blueGrey, black } from '@material-ui/core/colors';


const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[900]),
    backgroundColor: blueGrey[900],
    '&:hover': {
      backgroundColor: blueGrey[800],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
  },
}));



export default function CustomizedButtons2() {
  const classes = useStyles();

  return (
    <div>
      <ColorButton variant="contained" color="primary" disableElevation size="large" className={classes.margin}>
        Join
      </ColorButton>
    </div>
  );
}
