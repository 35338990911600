import axios from 'axios';

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://new-yeti.herokuapp.com/' :'http://localhost:3000'

const api = axios.create({
  baseURL: baseUrl
})

export const loginUser = async (loginData) => {
  const resp = await api.post('/auth/login', { ...loginData })
  localStorage.setItem('authToken', resp.data.token);
  api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
  return resp.data.user
}

export const getAllPosts = async () => {
  const resp = await api.get('/posts')
  console.log("****resp.data: ", resp.data)
  let posts = resp.data.sort( (a,b) => b.score - a.score)
  return posts
}

export const getAllComments = async (post_id) => {
  const resp = await api.get(`/posts/${post_id}/comments`)
  return resp.data
}

export const getAllVotes = async (post_id) => {
  const resp = await api.get(`/posts/${post_id}/votes`)
  return resp.data
}


export const registerUser = async (registerData) => {
  console.log("****registerData: ", registerData)
  const resp = await api.post('/users/', { user: registerData })
  localStorage.setItem('authToken', resp.data.token);
  api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
  return resp.data.user
}

export const verifyUser = async () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    api.defaults.headers.common.authorization = `Bearer ${token}`
    const resp = await api.get('/auth/verify');
    return resp.data
  }
  return false
}

export const createPost = async (data) => {
    const resp = await api.post('/posts', {post: {...data}})
    return resp.data
  }

export const deletePost = async (data) => {
    const resp = await api.delete(`/posts/${data.id}`, {post: {...data}})
  
    return resp.data
}


export const createComment = async (data) => {
    const resp = await api.post(`/posts/${data.post_id}/comments`, {comment: {...data}})
    return resp.data
}

export const createVote = async (data) => {
    const resp = await api.post(`/posts/${data.post_id}/votes`, {vote: {...data}})
    return resp.data
}

export const removeToken = () => {
  api.defaults.headers.common.authorization = null;
}


export const updatePost = async (id, data) => {
    const resp = await api.put(`/posts/${id}`, { post: data })
    console.log("updated", resp.data);
    
    return resp.data
  }

  export const deleteComment = async (data) => {
    const resp = await api.delete(`/posts/${data.post_id}/comments/${data.id}`, {comment: {...data}})
  
    return resp.data
}




