import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Grid,
} from "@material-ui/core";
import {
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CustomButton from './CustomButton'
import Typography from '@material-ui/core/Typography';


const useStylesPost = makeStyles((theme) => ({
  root: {
 
    color: '#fff',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#2F2D56',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#161436',
      color: '#fff',
      borderRadius: 0,
         borderBottom: '1px solid #2F2D56',
    },
    wrapper: {
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    button: {
      margin: theme.spacing(1)
    },
    input: {
      display: "none"
    },
    media: {
      height: 140,
    },
  },
}));

const Login = (props) => {
  const classes = useStylesPost();

  return (
    <div className="margin">
      <div className="signup flex justify-center">
        <div className="p-8">
            <Grid container>
               <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="margin-top width-80p margin-left y-center">
                  <ValidatorForm
                    onSubmit={(e) => {
                      e.preventDefault();
                      props.handleLogin();
                    }}
                  >
                   <Typography component="h4" variant="h4" color="innherit" className="white" gutterBottom>
              <h4 >
                Sign In
              </h4>
            </Typography>
                    <TextField
                      className="mb-6 w-full"
                      id="username"
                      label="username or email"
                      multiline
                      rowsMax="1"
                      variant="filled"
                      InputProps={{ classes, disableUnderline: true }} {...props}
                      name="username"
                      type="text"
                      value={props.formData.username}
                      onChange={props.handleChange}
                    />
                    <TextValidator
                      className="mb-6 w-full "
                      variant="filled"
                      InputProps={{ classes, disableUnderline: true }} {...props}
                      label="password"
                      onChange={props.handleChange}
                      type="text"
                      name="password"
                      type="password"
                      value={props.formData.password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <CustomButton
                      type="submit"
                    >Login</CustomButton>

                    <p></p>
                    <Link className="white" to="/register">Don't have an account? Register here</Link>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          {/* </Card> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
