import React, { useState, useEffect } from 'react';
import {
    getAllComments, createComment
} from '../services/api-helper'
import {
    IconButton,
    Button,
    TextField,
    MenuItem,
    Card
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
    fade,
    makeStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteComment } from '../services/api-helper';
import Comment from './Comment';

const useStylesPost = makeStyles((theme) => ({
    root: {
        border: '1px solid #e2e2e1',
        borderRadius: 4,
        paddingTop: theme.spacing(1),
        backgroundColor: '#fff',
        transition: theme.transitions.create(['border-color',
            'box-shadow']),
        '&:hover': {
            backgroundColor: '#fafafa',
        },

        '&$focused': {
            backgroundColor: '#fff',
        },
    },
    focused: {},
}));

function CommentList(props) {
    const [commentToDelete, setCommentToDelete] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const open = Boolean(anchorEl);
    const [comments, setComments] = useState([])
    let [comment, setComment] = React.useState("");
    const classes = useStylesPost();
    const currentUser = props.currentUser

    const handleDelete = (index) => {
        console.log("delete index ", index)
        handleClose()
        setCommentToDelete({ ...comments[index] })
        handleClickOpenDialog()
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleEdit = () => {
        handleClose()
    };
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDeleteCommentConfirmed = () => {
        handleCloseDialog()
        deleteComment(commentToDelete)
        console.log("comment to delete", commentToDelete);
        let newComments = [...comments]
        let index = newComments.findIndex(comment => comment.id === commentToDelete.id)
        delete newComments[index]
        newComments = newComments.filter(comment => comment !== undefined)
        setComments([...newComments])
        setCommentToDelete(null)
    }
    const sendCommentOnEnter = event => {
        if (event.key === "Enter" && !event.shiftKey) {
            comment = comment.trim();
            let newComment = { body: comment, user_id: currentUser.id, post_id: props.postId, user: currentUser, created_at: Date() }
            let respComment = createComment(newComment)
            newComment = { ...newComment, id: respComment.id }
            setComment("");
            setComments([...comments, newComment])
        }
    };
    useEffect(() => {
        getAllComments(props.postId).then(resp => {
            setComments(resp)
        })
            .catch(error => {
                console.log(error);
            });
    }, [props.postId])


    const displayDialog = () => {
        return (
            <div>

                <Dialog
                    open={commentToDelete && openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this?
                  </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            No, cancel
                  </Button>
                        <Button onClick={handleDeleteCommentConfirmed} color="primary" autoFocus>
                            Yes, delete
                  </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return (
        <div>
            <Card />
            <div className="box  ">
                <div className="border-top">
                    <TextField
                        label="Leave a comment"
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        onKeyDown={sendCommentOnEnter}
                        fullWidth
                        type="text"
                        variant="filled"
                        InputProps={{ classes, disableUnderline: true }} {...props}
                    />
                </div>
            </div>

            {displayDialog()}
            {comments.map((comment, index) => {

                return comment ? (
                    <div className="border-radius-bottom">
                        <Comment
                            handleClick={handleClick}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                            open={open}
                            anchorEl={anchorEl}
                            index={index}
                            comment={comment} />
                    </div>
                ) : null
            })}
        </div>
    );
}

export default CommentList;