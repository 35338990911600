import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const styles = {
  button: {
    background: 'linear-gradient(45deg, var(--background-start) 30%, var(--background-end) 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',

  },
};

class CustomButton extends React.Component {
  state = {
    color: 'default',
  };

  handleChange = event => {
    this.setState({ color: event.target.checked ? 'blue' : 'default' });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>

        <Button
        type="submit"
          className={`${classes.button}`}
          style={
         
            {
                  '--background-start': '#607d8b',
                  '--background-end': '#607d8b',
                }
            
          }
        >
             
        Login
       
        </Button>
      </React.Fragment>
    );
  }
}

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomButton);