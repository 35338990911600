import React, { useState } from 'react';
import { Card, Button } from "@material-ui/core";
import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import {
    fade,
    makeStyles,
} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { green, purple, lightBlue, black, red, teal, pink, blueGrey, grey, deepPurple, yellow, indigo, cyan, lime, amber } from '@material-ui/core/colors';


const useStylesPost = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        margin: theme.spacing(0),
        border: '1px solid #2F2D56',
        // backgroundColor: '#2F2D56',
        color: '#fff',
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#2F2D56',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#2F2D56',
            color: '#fff',
            // borderRadius: 0,
            // borderBottom: '2px solid #2F2D56',
        },
        '&$focused': {
            backgroundColor: '#2F2D56',
            boxShadow: `${fade(theme.palette.primary.main, 0.0)} 0 0 0 2px`,
        },
        button: {
            margin: theme.spacing(1)
        },
        input: {
            display: "none"
        }
    },
    // focused: {},
}));

const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: lightBlue[800],
        color: theme.palette.getContrastText(lightBlue[800]),

        '&:hover': {
            backgroundColor: lightBlue[600],
        },
    },
}))(Button);

function PostForm(props) {
    let titleValue, bodyValue, postId, index;
    if (props.title !== undefined) {
        titleValue = props.title
    }
    if (props.body !== undefined) {
        bodyValue = props.body
    }
    if (props.postId !== undefined) {
        postId = props.postId
    }
    if (props.index !== undefined) {
        index = props.index
    }
    console.log(index);

    const [title, setTitle] = useState(titleValue)
    const [body, setBody] = useState(bodyValue)
    const [video_url, setVideoUrl] = useState(bodyValue)
    const classes = useStylesPost();
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleChangeBody = (event) => {
        setBody(event.target.value)
    }
    const handleChangeVideoUrl = (event) => {
        setVideoUrl(event.target.value)
    }
    return (
        <div className="width-custom margin-top">
            <div className="flex-column  margin-sides">

                <div>
                    <Typography variant="h5">
                        <h4 className="white">
                            {props.method === "create" ?
                                "Create a post"
                                : "Edit your post"} </h4>
                    </Typography>

                    <div>
                        <TextField
                            type="text"
                            id="title"
                            label="Title"
                            name="title"
                            value={title}
                            onChange={handleChangeTitle}
                            multiline
                            fullWidth={true}
                            rowsMax="1"
                            variant="filled"
                            InputProps={{ classes, disableUnderline: true }} {...props} />
                    </div>

                    <div className="margin-top-post-form">
                        <TextField
                        // style={{ backgroundColor: '#161436', color: '#fff' }}
                            id="body"
                            name="body"
                            rows="6"
                            value={body}
                            onChange={handleChangeBody}
                            label="Add text or a YouTube video (link)"
                            fullWidth={true}
                            multiline
                            rowsMax="6"
                            variant="filled"
                            InputProps={{ classes, disableUnderline: true }} {...props} />
                    </div>
                    <div>
                        <ColorButton
                            variant="contained"
                            size="large"
                            disableElevation
                            onClick={
                                (event) => {
                                    event.preventDefault();
                                    props.method === "create" ?
                                        props.handleSubmit({ title, body, video_url })
                                        : props.handleSubmit({ title, body, postId, index, video_url })
                                }}
                        >
                            {props.method === "create" ? "Post" : "Edit your post"}
                        </ColorButton>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PostForm;