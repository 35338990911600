import React, { useState, useEffect } from 'react';

import PostForm from './PostForm';

import PostList from './PostList';

import {
    getAllPosts, createPost, updatePost, deletePost
} from '../services/api-helper'

function Feed(props) {
    const [posts, setPosts] = useState([])
    const [editingIndex, setEditingIndex] = useState(null);
    
    useEffect(() => {
        
        getAllPosts()
            .then(resp => {
                setPosts(resp)
            })
            .catch(error => {
                console.log(error);
            });
        
    }, [])
    
    const handleCreateSubmit = ({ title, body, video_url }) => {
        const user_id = props.currentUser.id
        let actual_video_url = video_url || extractVideoUrl(body)
        // let newBody = body.replace(video_url, "")
        createPost({ title, body: body || video_url, video_url: actual_video_url, user_id }).then(resp => {
            setPosts([resp, ...posts])
        })
    }

    const extractVideoUrl = (body) =>{
        let videoUrl = "";
        if(body && body.indexOf("http") >= 0 && body.indexOf( "youtu") >= 0){
            let tokens = body.split(" ")
            tokens = tokens.filter( token => token.indexOf("http") >= 0 && token.indexOf( "youtu") >= 0)
            videoUrl = tokens[0]
        }
        return videoUrl;
    }
    const handleSetEditingIndex = (index) =>{
            setEditingIndex(index)
    }

    const handleDeletePost = (index) =>{

        let post = {...(posts[index])};

        deletePost(post).then(resp => {
            let updatedPosts = [...posts]
          
            updatedPosts.splice(index, 1)
            console.log("response: ", resp)
            console.log("index: ", index)
            console.log("updatedPots: ", updatedPosts)
            
            setPosts([...updatedPosts])
        })
    }

    const handleEditSubmit = ({ title, body, postId, index }) => {
        const user_id = props.currentUser.id
       
        
        

        updatePost( postId, { title, body, user_id }).then(resp => {
            
            let updatedPosts = [...posts]
          
            
            updatedPosts.splice(index, 1, resp)
            console.log("response: ", resp)
            console.log("index: ", index)
            console.log("updatedPots: ", updatedPosts)
            
            setPosts([...updatedPosts])
          
            setEditingIndex(null)
        })
    }
    return (
        <div className="breakpoint-post-form">
            <div className="margin-top-move-up">
            {null
              ||
            <PostForm
                handleSubmit={handleCreateSubmit}
                method={"create"}
            />}
            </div>
            <div className="breakpoint margin-top desktop-post-width">
            {null ||
                <PostList
            
                setEditingIndex={handleSetEditingIndex}
                posts={posts}
                currentUser={props.currentUser}
                handleSubmit={handleEditSubmit}
                editingIndex={editingIndex}
                deletePost={handleDeletePost}
            />
           
            }
            </div>
        </div>
    );
}

export default Feed;