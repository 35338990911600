import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, deepPurple, blue, blueGrey, black } from '@material-ui/core/colors';


const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    '&:hover': {
      backgroundColor: blueGrey[400],
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
  },
}));



export default function CustomizedButtons() {
  const classes = useStyles();

  return (
    <div>
      <ColorButton variant="contained" color="primary" disableElevation size="large" className={classes.margin}>
        Login
      </ColorButton>
    </div>
  );
}
